<template>
<div>
    <section class="page-title page-title-7" id="page-title">
        <div class="page-title-wrap bg-overlay bg-overlay-dark-3 bg-section" style="background-image: url(&quot;assets/images/sliders/3.jpg&quot;);">

            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <div class="title">
                            <h1 class="title-heading">Nos Réalisations</h1>
                            <p class="title-desc">Notre objectif est de permettre l'accès à l’électricité dans des zones rurales, urbaines et indistrielles.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="breadcrumb-wrap">
            <div class="container">
                <ol class="breadcrumb d-flex">
                    <li class="breadcrumb-item"><a href="">Accueil</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Nos réalisations</li>
                </ol>

            </div>
        </div>

    </section>

        <section class="projects projects-gallery bg-grey">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 offset-lg-3">
                    <div class="heading heading-5 text-center">
                        <p class="heading-subtitle">Innovation et qualités</p>
                        <h2 class="heading-title">Nos Réalisations</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel1.jpg" alt=" item">

                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel1.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel2.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel2.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel3.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel3.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel4.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel4.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel5.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel5.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel7.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel7.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel5.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel5.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>


                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel8.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel8.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>


                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel9.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel9.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>


                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel10.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel10.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>


                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel">
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel11.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel11.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>


                <div class="col-12 col-md-6 col-lg-4 team-item ">
                    <div class="project-panel"> 
                        <div class="project-panel-holder">
                            <div class="project-img"><img class="img-fluid" style="width: 100%; height: 350px" src="assets/images/realisations/rel12.jpg" alt=" item">
                                <div class="project-hover">
                                    <div class="project-action">
                                        <div class="project-zoom"><i class="far fa-eye"></i><a class="img-gallery-item" href="assets/images/realisations/rel12.jpg" title="Eclairage publique de la ville de bandaka"></a></div>
                                    </div>

                                </div>

                                <div style="width: 100%; height: 50px; position: absolute; bottom: 0; z-index: 100; background-color: rgba(2, 15, 36, 0.6)">
                                    <h2 class="text-center text-white mt-3" style="font-weight: 400; font-size: 14px; letter-spacing: 2px">Eclairage publique de la ville de bandaka</h2>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </section>

</div>
</template>

<script>
export default {
    name: "GalleryPage",
}
</script>
